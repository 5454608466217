/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import "./../node_modules/swiper/swiper-bundle.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";



.bottom-sheet-popover .popover-content {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: calc(100% - 150px) !important;
  // height: 20%;
  max-height: 150px;
  min-height: 150px;
  border-radius: 0 !important;
}
//   ion-app {
//     /*animation of native keyboard show*/
//     transition: margin 300ms;
// }

ion-badge {
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.cart-modal {
  --height: 60%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
  }

  .scanner-modal {
  }
}
.order-preference-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.customer-details-modal {
  --height: 75%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.order-status-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.footer_menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.menu-pop {
  --height: 60%;
  // --min-height: 60%;
  --width: 80%;
  --offset-x: 0px;
  --offset-y: 0px;
  align-items: end;
  // padding-top: 20px;
  padding-bottom: 70px;
  backdrop-filter: blur(2px);
  &::part(content) {
    border-radius: 16px;
    background: transparent;
    // padding-bottom: 70px;
    // box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.shift-menu-pop {
  padding-bottom: 100px;
}

// .menu-pop ::ng-deep {
//   .modal-wrapper {
//     border-radius: 16px;
//     .ion-page {
//       justify-content: start;
//     }
//   }
// }

.order-preference-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.order-status-modal {
  --height: 50%;
  align-items: end;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.footer_menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.payment-modal {
  --height: 80%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    // border-radius: 20px 20px 0 0;
    .ion-page {
      justify-content: start;
    }
  }
}

.item-customization-modal {
  --height: 80%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}
.repeat-customization-modal {
  --height: 40%;
  --border-radius: 16px 16px 0 0;
  align-items: end;
  .modal-wrapper {
    .ion-page {
      justify-content: start;
    }
  }
}

ion-toast.custom-toast   {

  &::part(container) {
    margin-bottom: 80px;
  }
}

ion-toast {
  transform: translateY(80px);
}

.w-100 {
  width: 100% !important;
}
